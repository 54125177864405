import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Seo from '~components/Seo'
import DataViewer from '~utils/DataViewer'
import styled from '@emotion/styled'
import Section from '~components/Section'
import Image from '~components/Image'
import { mobile, tablet, PageHeader, Headline, Subtitle } from '~styles/global'
import RichText from '~components/RichText'
import ProductGrid from '~components/ProductGrid'

const AboutPage = ({ data }) => {
	const { page, products } = data
	return(
		<>
			<Seo 
				title={page.title}
				metaTitle={page.seo?.metaTitle}
				description={page.seo?.metaDescription}
				image={page.seo?.socialImage}
			/>
			<PageHeader>
				<Headline>{page.headline}</Headline>
				<Subtitle>{page.subtitle}</Subtitle>
			</PageHeader>
			<Section>
				<FeatureImage image={page.image}/>
				<Text>
					<RichText content={page.text}/>	
				</Text>
			</Section>
			<Section><Line /></Section>
			<FPHeader>
				<FPHeading>
					{products.headline}
				</FPHeading>
				<FPSubheading>
					{products.subtitle}
				</FPSubheading>
			</FPHeader>
			<FeaturedProducts products={products.productGrid}/>
			<DataViewer data={data} name="data"/>
		</>
	)
}

const FeatureImage = styled(Image)`
	margin-bottom: var(--xxxl);
	grid-column: 4/10;
	border-radius: 10px;
	overflow: hidden;
	${tablet}{
		grid-column: 3/11;
	}
	${mobile}{
		grid-column: 2/12;
	}
`
const Text = styled.div`
	grid-column: 4/10;
	text-align: center;
	max-width: 580px;
  margin: 0 auto var(--xxxl);
	${mobile}{
		grid-column: span 12;
	}
`
const FPHeader = styled(Section)`
	padding-top: var(--xl);
	margin-bottom: var(--xxl);
`
const FPHeading = styled.h6`
	grid-column: span 12;
	text-align: center;
	margin-bottom: var(--l);
`
const FPSubheading = styled.h3`
	grid-column: span 12;
	text-align: center;
`
const FeaturedProducts = styled(ProductGrid)`
	margin-bottom: var(--xxxl);
`
const Line = styled.div`
	border-bottom: 1px solid;
	grid-column: span 12;
`

AboutPage.propTypes = {
	data: PropTypes.object,
}

export const query = graphql`
  query AboutPageQuery($slug: String) {
    page: sanityAboutPage(slug: {current: {eq: $slug}}) {
      title
			headline
			subtitle
			image {
				...imageWithAlt
			}
			text: _rawText
			seo {
				...seo
			}
    }
		products: sanityProductsPage {
			headline
			subtitle
			productGrid {
				...productThumb
			}
		}
  }
`

export default AboutPage